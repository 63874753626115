import React from 'react';

const PrivacyPolicyPT = () => (
  <>
    ANEXO I
<br/>POLÍTICA DE PROTEÇÃO DE DADOS E PRIVACIDADE YOUSHIP
<br/>
<br/>Versão 1.2 – 19 de julho de 2021
<br/>
<br/>A ShipNow Technologies, Unipessoal, Lda. (doravante YOUSHIP) oferece o serviço YOUSHIP, consistindo numa plataforma de entrega de mercadorias.
<br/>A YOUSHIP compreende que a utilização dos seus dados pessoais no âmbito do serviço YOUSHIP requer a sua total confiança. A integridade dos seus dados pessoais é, assim, uma das nossas principais preocupações.
<br/>Prevemos na nossa atividade padrões rigorosos de privacidade, processando os seus dados pessoais apenas no âmbito de finalidades claramente identificadas e em integral respeito dos seus direitos enquanto titular de dados pessoais.
<br/>São estas as motivações e o fundamento da presente Política de Proteção de Dados e Privacidade YOUSHIP.
<br/>
<br/>I.	Dos Dados Pessoais
<br/>
<br/>A ShipNow Technologies, Unipessoal, Lda., sociedade unipessoal por quotas constituída em Portugal, Pessoa Coletiva nº 513 590 234, com sede no Instituto Pedro Nunes, Rua Pedro Nunes, 3030-199 Coimbra e parte responsável pela oferta do serviço YOUSHIP ao público, na prossecução das suas atividades no âmbito do serviço YOUSHIP procede à recolha e tratamento de dados pessoais de pessoas singulares, tais como:
<br/>
<br/>No interface CLIENTE:
<br/>•	Nome: remetente e destinatário;
<br/>•	Contacto postal de remetente e destinatário;
<br/>•	Contacto de email de remetente e destinatário;
<br/>•	Contacto telefónico (fixo e móvel) de remetente e destinatário;
<br/>•	NIF;
<br/>•	Dados de localização aproximada de dispositivos móveis (identificador do dispositivo móvel, localização GPS e endereço IP do dispositivo), no âmbito do uso do serviço YOUSHIP;
<br/>
<br/>No interface TRANSPORTADOR:
<br/>•	Nome e fotografia;
<br/>•	Contacto postal;
<br/>•	Contacto de email;
<br/>•	Contacto telefónico (fixo e móvel);
<br/>•	Número de documento de identificação;
<br/>•	Número de Carta de Condução;
<br/>•	NIF e comprovativo de início de atividade fiscal;
<br/>•	Coordenadas de conta bancária (IBAN);
<br/>•	Dados de localização aproximada de dispositivos móveis (identificador do dispositivo móvel, localização GPS e endereço IP do dispositivo), no âmbito do uso do serviço YOUSHIP.
<br/>
<br/>II.	Do âmbito subjetivo de aplicação
<br/>
<br/>Esta Política de Privacidade é aplicável a todos os Utilizadores, entendidos como todos os que utilizam a plataforma digital, que tenham efetuado o respetivo registo (login) e criado uma Conta na plataforma YOUSHIP, confirmando aceitar os respetivos Termos e Condições do serviço YOUSHIP, nomeadamente:
<br/>
<br/>•	Clientes: pessoas que solicitam ou recebem encomendas através do serviço YOUSHIP;
<br/>•	Transportadores: pessoa inscrita na Plataforma YOUSHIP, responsável pela realização do serviço de entregas, a título independente e enquanto prestador de serviços à ShipNow Tecnhologies, Unipessoal, Lda.
<br/>
<br/>III.	Do tratamento de Dados Pessoais e das decorrentes operações concretas de processamento
<br/>
<br/>O tratamento dos dados pessoais é da responsabilidade da YOUSHIP (sem prejuízo do disposto em IX.) e consiste na operação ou no conjunto de operações efetuados sobre aqueles dados pessoais, por meios automatizados ou não automatizados, tais como:
<br/>
<br/>•	Recolha;
<br/>•	Registo e conservação;
<br/>•	Organização;
<br/>•	Adaptação ou alteração;
<br/>•	Consulta e utilização;
<br/>•	Divulgação, independentemente da forma de disponibilização;
<br/>•	Comparação ou interconexão;
<br/>•	Limitação, apagamento ou destruição,
<br/>
<br/>com vista à colocação do serviço YOUSHIP ao dispor dos utilizadores.
<br/>
<br/>No formulário de consentimento, o Cliente autoriza expressamente a YOUSHIP e todos aqueles que esta mobilizar para o cumprimento do serviço YOUSHIP a enviar mensagens. O Cliente poderá solicitar a qualquer momento o encerramento do envio das notificações, sendo de imediato desativado pela YOUSHIP, reconhecendo todavia que tal pedido poderá prejudicar a plena fruição do serviço YOUSHIP e no limite inviabilizar o seu cumprimento, sem que tal implique qualquer tipo de responsabilidade para a YOUSHIP, nos exatos termos emergentes dos Termos e Condições do Serviço YOUSHIP e do respetivo termo de consentimento para o tratamento de dados pessoais.
<br/>
<br/>No âmbito do serviço YOUSHIP, qualquer comunicação entre a YOUSHIP e qualquer Utilizador será efetuada para os contactos telefónicos (fixos, móveis ou em formato SMS para rede móvel) ou correio eletrónico comunicados pelas partes, podendo ainda a YOUSHIP efetuar quaisquer comunicações através da Plataforma YOUSHIP e ainda mediante inserção nas faturas emitidas pelo serviço YOUSHIP, em local visível e com o devido destaque.
<br/>
<br/>Na execução do Serviço YOUSHIP, o processamento de dados pessoais compreende, designadamente:
<br/>•	O envio de notificações e/ou PUSH no telemóvel e no e-mail comunicado através da conta pessoal dos Utilizadores, de acordo com o respetivo perfil de Cliente ou Transportador;
<br/>•	Recolha de informações detalhadas relacionadas com o serviço de entregas, como o nome, morada, data e hora pretendida, montante e forma de pagamento, tipo de Serviço e distância do ponto de recolha até ao ponto de entrega;
<br/>•	No perfil de Transportador, a sua localização concreta, relevante para a apresentação de serviços na área em que se encontra ou nas proximidades.
<br/>
<br/>IV.	Do consentimento do titular dos dados pessoais
<br/>
<br/>A YOUSHIP requer ao Utilizador, titular de dados pessoais, em todos os casos, o seu consentimento livre, específico, informado e explícito para o tratamento dos respetivos dados, mediante modelos criados caso a caso, consoante o tipo e extensão concretos do referido tratamento.
<br/>
<br/>Do eventual consentimento de menores 
<br/>Nos termos do artigo 8º do RGPD, os dados pessoais de menores só podem ser objeto de tratamento com base no consentimento previsto na alínea a) do nº 1 do artigo 6º do RGPD e relativo à oferta direta de serviços da sociedade de informação quando os mesmos já tenham completado 13 (treze) anos de idade. 
<br/>
<br/>O serviço YOUSHIP só pode ser subscrito, tanto no perfil Cliente, como no perfil Transportador, por maiores de 18 (dezoito) anos.
<br/>
<br/>V.	Responsável pelo tratamento de dados pessoais
<br/>
<br/>A YOUSHIP conhece e cumpre os normativos vigentes no que respeita ao tratamento de dados pessoais, previstos no Regulamento (UE) 2016/679, do Parlamento Europeu e do Conselho de 27 de abril de 2016 (doravante, RGPD) e na Lei nº 58/2019 de Agosto, sem prejuízo de toda a demais legislação de base nacional à qual se encontra vinculada.
<br/>
<br/>A YOUSHIP é responsável pelo tratamento dos dados pessoais, por meios automatizados ou não, desde a sua recolha, organização, conservação até à eliminação. 
<br/>
<br/>A YOUSHIP dispõe internamente de um processo de registo contínuo e atualizado das atividades de tratamento de dados pessoais que realiza.
<br/>
<br/>VI.	Finalidades e fundamentos para o tratamento de dados pessoais
<br/>
<br/>Os dados pessoais serão apenas tratados com vista a um conjunto de finalidades determinadas, considerando igualmente um conjunto de fundamentos legais para esse tratamento, dentre os quais se incluem:
<br/>
<br/>•	Realização de diligências pré-contratuais;
<br/>•	Oferta aos Clientes e Transportadores do Serviço YOUSHIP;
<br/>•	Cumprimento dos contratos de Serviço YOUSHIP com Clientes e Transportadores;
<br/>•	Controlo do progresso do Serviço YOUSHIP;
<br/>•	Processamento e pagamento do Serviço YOUSHIP e respetiva faturação.
<br/>•	Cumprimento de legislação em vigor e de todos os demais imperativos legais;
<br/>•	Deveres legais de conservação e integridade de documentação;
<br/>•	Cumprimento de contratos com o titular dos dados quais o YOUSHIP é parte;
<br/>•	Cumprimento de certificações de Qualidade;
<br/>•	Controlo e registo de uso de instalações e equipamentos;
<br/>•	Segurança e integridade de instalações e equipamentos;
<br/>•	Comunicações subsequentes com o titular dos dados para efeitos de marketing, relativamente aos quais a todo o tempo este poderá requerer 
<br/>à YOUSHIP a cessação de envio de comunicações.
<br/>
<br/>VII.	Prazo de conservação dos dados
<br/>
<br/>Os dados pessoais serão conservados pela YOUSHIP por um período de tempo definido por norma legal ou regulamentar ou, na falta desta, o que se revele necessário para a prossecução da respetiva finalidade, tendo em consideração as finalidades e fundamentos legais para o tratamento acima referidos e todos os requisitos e prazos legalmente definidos, designadamente os prazos de prescrição dos direitos correspetivos. Em conformidade, em todos os casos em que existe um prazo de conservação de dados imposto por lei, só pode ser exercido o direito ao apagamento previsto no artigo 17º do RGPD findo esse prazo. 
<br/>
<br/>A YOUSHIP garante a conservação dos dados pessoais pelo período estritamente necessário ao cumprimento da finalidade do seu específico tratamento, assim como o seu apagamento (ou anonimização, se e quando aplicável/necessário) imediatamente após transcorrido aquele período e/ou mediante solicitação do respetivo titular, considerando sempre e em todos os casos os referidos requisitos e prazos legalmente definidos.
<br/>VIII.	Direitos do titular de dados pessoais
<br/>
<br/>O titular dos dados tem o direito de, a todo o tempo e gratuitamente, requerer à YOUSHIP:
<br/>
<br/>•	O acesso aos seus dados pessoais;
<br/>•	A retificação ou correção dos seus dados pessoais;
<br/>•	O apagamento dos seus dados (sem prejuízo do disposto em VII. sobre o prazo de conservação dos dados);
<br/>•	A limitação do tratamento dos seus dados pessoais (idem);
<br/>•	A oposição ao tratamento dos seus dados;
<br/>•	A portabilidade dos seus dados para entidade por si indicada, conquanto se trate de dados pessoais armazenados em suporte eletrónico.
<br/>
<br/>Em todos os casos, existindo norma ou obrigação legalmente imposta que se sobreponha a estes direitos, a YOUSHIP reserva o direito de não executar o pedido (ou deste pedido ser objeto de restrições ou condicionamentos, se e quando aplicável), indicando sempre e em todos os casos o respetivo fundamento ao titular interessado.
<br/>
<br/>O titular dos dados pessoais poderá apresentar qualquer reclamação junto da Comissão Nacional de Proteção de Dados (doravante, CNPD), na qualidade de Autoridade de Controlo em Portugal, tal como definida nos números 21 e 22 do artigo 4º e no artigo 51º do RGPD.
<br/>
<br/>IX.	Transmissão de dados pessoais a terceiros e intervenção de subcontratantes
<br/>
<br/>Da transmissão a terceiros
<br/>A YOUSHIP, no decurso da sua atividade, poderá transmitir dados pessoais que se encontrem na sua posse a terceiras entidades, definidas nos termos do número 10 do artigo 4º do RGPD, em cumprimento de deveres legais, regulamentares, pré-contratuais ou contratuais, conquanto tal seja imposto por lei ou regulamento ou indispensável à prossecução das suas atividades. 
<br/>
<br/>Da intervenção de subcontratantes
<br/>A YOUSHIP, no decurso da sua atividade, estabelece relações de subcontratação com os Transportadores, enquanto terceiras entidades (tal como definidas no número 8 do artigo 4º do RGPD), os quais são responsáveis pelo processamento de dados pessoais de Clientes, por conta da YOUSHIP. Em conformidade, a YOUSHIP requer o competente e prévio consentimento aos titulares dos dados pessoais objeto deste tipo de tratamento, respeitando todos os requisitos previstos no RGPD para o efeito, celebrando com todos os Transportadores o prévio e competente acordo de tratamento de dados que legitima este tratamento.
<br/>
<br/>Os dados dos Clientes transmitidos aos Transportadores são o nome próprio, o contacto telefónico, as informações sobre os bens a transportar e as moradas ou pontos de recolha e entrega. 
<br/>No caso dos Transportadores é partilhado com os Clientes o seu nome próprio, o contacto e a fotografia associada à respetiva conta. 
<br/>
<br/>O Transportador assume, neste contexto, integral responsabilidade pela segurança, integridade e inviolabilidade dos dados pessoais a que aceder e que processar em cumprimento do serviço YOUSHIP, designadamente aqueles pertencentes aos Clientes, sem prejuízo de todos os demais, bem como a absoluta interdição de os ceder, transmitir ou dar a conhecer a qualquer terceiro estranho ao cumprimento pontual deste Contrato.
<br/>
<br/>O Transportador assume ainda responsabilidade integral pela sua infraestrutura informática e de comunicações, bem como por todos os demais requisitos técnicos que mobilizará para o cumprimento do objeto contratual ora definido, designadamente assegurando que se encontram livres de quaisquer vírus, malware, spyware ou outras formas de perturbação ou intrusão, garantindo a todo o tempo o uso das medidas técnicas que emprega para proteger os seus próprios dados, mas nunca menos que garantindo o cumprimento dos mais elevados padrões de segurança e integridade em uso neste setor de atividade, bem como abstendo-se de utilizar redes wireless públicas ou outras que não garantam os adequados níveis de segurança.
<br/>
<br/>Nos pagamentos do Serviço YOUSHIP, torna-se necessária a transmissão do nome próprio, da morada e do e-mail para os processadores e facilitadores de pagamentos, parceiros de pagamentos eletrónicos.
<br/>
<br/>A YOUSHIP reserva igualmente o direito de transmitir dados pessoais em caso de reclamações, litígios e/ou outros imperativos legais, designadamente a entidades públicas e/ou fiscalização, bem como por quaisquer motivos de segurança,  proteção ou interesse público relevante. 
<br/>
<br/>Em todas as partilhas de dados pessoais não mencionadas nesta Política de Privacidade e/ou nos Termos e Condições, a YOUSHIP pode notificar os Utilizadores por forma a obter o seu consentimento.
<br/>
<br/>X.	Cookies
<br/>
<br/>Sem prejuízo das informações e dados recolhidos através de outros meios técnicos, a YOUSHIP procede, adicionalmente, à recolha de informação anónima através do seu Website, designadamente informações relativas ao tipo de browser internet utilizado, sistemas operativos e data e hora de acesso ao website, recorrendo, para tanto a tecnologias de controlo (cookies) para reunir essa informação.
<br/>Cookies são pequenos ficheiros de informação gerados pelos browsers dos utilizadores e que são armazenados no disco rígido. Estes ficheiros contêm informação não pessoal, mas que permitem a identificação do Utilizador por parte da YOUSHIP.
<br/>O utilizador autoriza expressamente a utilização de Cookies por parte da YOUSHIP. A informação anónima recolhida pela YOUSHIP mediante o recurso a cookies destina-se exclusivamente a ser processada para ajudar a melhorar o conteúdo e funcionamento do Website, não havendo lugar à recolha de quaisquer dados pessoais dos utilizadores, para além daqueles que por estes sejam fornecidos de forma livre e consciente, nos termos da presente Política.
<br/>Se o utilizador assim o desejar, poderá desativar os cookies através das definições do seu browser.
<br/>
<br/>XI.	Compromisso de proteção 
<br/>
<br/>A YOUSHIP garantirá a implementação de todos os procedimentos formais e operacionais para a tutela dos dados pessoais objeto de tratamento, bem como para o registo corrente e atualizado de todas as atividades de tratamento, bem como a realizar o competente estudo prévio das atividades de tratamento de dados pessoais a lançar no futuro, garantindo a sua compatibilidade com o RGPD e demais legislação aplicável.
<br/>
<br/>A YOUSHIP fará os melhores esforços no sentido de garantir e manter em funcionamento todos os meios técnicos ao seu dispor para evitar a perda, má utilização, alteração, acesso não autorizado e apropriação indevida dos dados pessoais que lhe sejam facultados pelos titulares, sem prejuízo da falibilidade das medidas de segurança da Internet.
<br/>
<br/>A YOUSHIP declina qualquer responsabilidade pelos danos sofridos pelos utilizadores e provocados ou não por terceiros, através do acesso ilegítimo a dados transmitidos por aqueles utilizadores através do seu portal Internet e/ou da sua infraestrutura informática.
<br/>
<br/>A YOUSHIP obriga-se a notificar a CNPD nos termos e prazos previstos no artigo 33º do RGPD, caso tenha conhecimento de um qualquer evento de violação de dados pessoais, tal como definido no número 12 do artigo 4º do RGPD.
<br/>
<br/>XII.	Contactos e Encarregado de Proteção de Dados
<br/>
<br/>O titular dos dados pessoais poderá exercer os seus direitos de retificação, modificação ou cancelamento daqueles dados ou solicitar qualquer informação respeitante ao seu tratamento mediante solicitação escrita dirigida à YOUSHIP para a morada indicada em I. ou para o endereço eletrónico: rgpd@you-ship.com
<br/>
<br/>A YOUSHIP nomeou um Encarregado de Proteção de Dados, nos termos previstos na legislação em vigor, cujo contacto é: rgpd@you-ship.com
<br/>
<br/>XIII.	Disposições finais
<br/>
<br/>A YOUSHIP poderá, em qualquer altura e sem necessidade de aviso prévio, alterar a presente Política, designadamente em virtude de necessidade da sua adaptação a eventuais alterações legislativas ou a recomendações da CNPD, caso em que a YOUSHIP disponibilizará as alterações introduzidas, em área acessível a todos os seus utilizadores.
<br/>
<br/>Coimbra, 19 de Julho de 2021.
<br/>
  </>
);

export default PrivacyPolicyPT;
